import React from 'react'
// import { Link } from 'gatsby'
import Parallax from 'react-rellax'

import Layout from '../components/layout'
import Question from '../components/question'
// import Image from '../components/image'
import SEO from '../components/seo'

const IndexPage = () => (
  <Layout>
    <SEO
      title="Home"
      keywords={[
        `mixed-methods researcher`,
        `user researcher`,
        `product researcher`,
        `ux`,
        `strategist`,
        `calgary`,
      ]}
    />

    <section class="hero">
      <div class="s-container">
        <div class="grid">
          <div className="hero__heading">
            <h1>
              I’m an experienced mixed-methods user researcher focused on
              answering three questions about your users.
            </h1>
          </div>

          <div className="hero__questions">
            <ol className="questions">
              <li>
                <a href="#question-1">What do your users need?</a>
              </li>
              <li>
                <a href="#question-2">How do you design for these needs?</a>
              </li>
              <li>
                <a href="#question-3">Do your solutions work?</a>
              </li>
            </ol>
          </div>

          <div className="shapes">
            <Parallax speed={-3.5} className="big-circle shape">
              <img src="/images/big-circle.svg" class="" />
            </Parallax>
            <Parallax className="pink-dot shape">
              <img src="/images/pink-dot.svg" class="" />
            </Parallax>
            <Parallax speed={-3.5} className="teal-square shape">
              <img src="/images/teal-square.svg" class="" />
            </Parallax>
            <Parallax speed={-2.5} className="tiny-blue-circle shape">
              <img src="/images/tiny-blue-circle.svg" class="" />
            </Parallax>
            <Parallax speed={-1.5} className="teal-triangle shape">
              <img src="/images/teal-triangle.svg" class="" />
            </Parallax>
          </div>
        </div>
      </div>
    </section>

    <Question id="question-1">
      <div class="s-photo-container s-photo-container--first">
        <div class="photo"></div>
      </div>
      <div class="s-container">
        <div class="grid">
          <div class="s-question__num">1</div>
          <div class="s-question__intro">
            <h2>What do your users need?</h2>
            <p class="subhead">
              You can’t just ask your users what they want – they wouldn’t be
              able to tell you, even if they wanted to. Instead, you have to
              discover what they need.
            </p>
            <p class="subhead">
              I help you understand your users’ needs by combining quantitative
              data (collected by measuring things) with qualitative data
              (collected by observing your users or talking to them).
            </p>
          </div>
          <div class="s-question__feature-1-1">
            <h3>Analytics Review</h3>
            <p>
              Popping the analytics hood of your website or product to learn
              more about how your users behave.
            </p>
          </div>
          <div class="s-question__feature-2-1">
            <h3>Content Audit</h3>
            <p>
              Analyzing your existing content against your users' needs to
              identify gaps or shortcomings.
            </p>
          </div>
          <div class="s-question__feature-3-1">
            <h3>Competitive Analysis</h3>
            <p>
              Figuring out what problems your competition is trying to solve and
              where you can do it better.
            </p>
          </div>
          <div class="s-question__feature-4-1">
            <h3>Contextual Inquiry</h3>
            <p>
              Observing folks in their natural context and asking them to fill
              in the gaps of what I'm able to see.
            </p>
          </div>
          <div class="s-question__feature-1-2">
            <h3>Diary Studies</h3>
            <p>
              Capturing people's behaviours, feelings, and thoughts over time to
              gain insight into their lives.
            </p>
          </div>
          <div class="s-question__feature-2-2">
            <h3>Focus Groups &amp; Interviews</h3>
            <p>
              Facilitating discussions with your users to reveal their desires,
              attitudes, and experiences.
            </p>
          </div>
          <div class="s-question__feature-3-2">
            <h3>Secondary Research</h3>
            <p>
              Synthesizing &amp; remixing research that others have done to
              arrive at actionable insights.
            </p>
          </div>
          <div class="s-question__feature-4-2">
            <h3>Surveys</h3>
            <p>
              Collecting a large amount of data from a representative group of
              about their behaviours or attitudes.
            </p>
          </div>
        </div>
      </div>
    </Question>
    <Question id="question-2" styleName="s-question--dark">
      <div class="s-photo-container s-photo-container--second">
        <div class="photo right"></div>
      </div>
      <div class="s-container">
        <div class="grid grid-alt">
          <div class="s-question__num">2</div>
          <div class="s-question__intro">
            <h2>How do you design for these needs?</h2>
            <p class="subhead">
              Without a clear definition, your users can become whoever you want
              them to be. The result is a Frankenstein product or website that
              is everything to no one.
            </p>
            <p class="subhead">
              I distill user research into a format that your designers can
              easily apply, which helps to ensure that design decisions are
              informed by evidence, instead of assumptions.
            </p>
          </div>
          <div class="s-question__feature-1-1">
            <h3>Card Sorting</h3>
            <p>
              Uncovering how your users organize information, so we can
              structure content in a way that makes sense to them.
            </p>
          </div>
          <div class="s-question__feature-1-2">
            <h3>Content Analysis</h3>
            <p>
              Extracting themes and making meaning from unstructured information
              (like interviews or open-ended survey questions).
            </p>
          </div>
          <div class="s-question__feature-2-1">
            <h3>Experience Mapping</h3>
            <p>
              Visualizing the steps that your users take to reach their goals,
              and identifying the pain points, behaviours, thoughts, and
              feelings they encounter.
            </p>
          </div>
          <div class="s-question__feature-2-2">
            <h3>Personas</h3>
            <p>
              Transforming data points into a concrete representation that feels
              less like a spreadsheet and more like a real person.
            </p>
          </div>
        </div>
      </div>
    </Question>
    <Question id="question-3" styleName="s-question--last">
      <div class="s-photo-container s-photo-container--third">
        <div class="photo"></div>
      </div>
      <div class="s-container">
        <div class="grid">
          <div class="s-question__num">3</div>
          <div class="s-question__intro">
            <h2>Do your solutions work?</h2>
            <p class="subhead">
              If your people can’t use your product – they won’t. Your customers
              should be able to achieve their goals, and their experience
              shouldn’t leave them feeling frustrated.
            </p>
            <p class="subhead">
              No design is perfect after the first attempt. I’ll provide you
              with the insights you need to improve your user experience by
              testing your solutions with your users.
            </p>
          </div>

          <div class="s-question__feature-1-1">
            <h3>A/B Testing</h3>
            <p>
              Comparing two versions of the same design to see which performs
              better (and is statistically significant).
            </p>
          </div>
          <div class="s-question__feature-2-1">
            <h3>Usability Testing</h3>
            <p>
              Evaluating both the information and visual design of your product,
              by observing where your users encounter problems or experience
              confusion.
            </p>
          </div>
          <div class="s-question__feature-1-2">
            <h3>User Testing</h3>
            <p>
              Assessing if your users think your concept hits the mark and
              achieves your intended outcomes.
            </p>
          </div>
          <div class="s-question__feature-2-2">
            <h3>Tree Testing</h3>
            <p>
              Evaluating the information design of your product, by asking your
              users to find information only using your product’s structure,
              without any visual cues.{' '}
            </p>
          </div>
        </div>
      </div>
    </Question>

    <section class="s-testimonial">
      <div className="s-container s-testimonial__intro">
        <h2 className="h1">
          Trusted to answer <br />
          these questions by:
        </h2>
      </div>

      <div className="s-testimonial-list">
        <div className="testimonial-card adobe">
          <img src="/images/adobe.png" className="logo" alt="Adobe Logo" />
          <div className="quote-content">
            <input type="checkbox" id="expanded-adobe" />
            <p className="quote">
              “Allison's remarkable ability to delve into complex problems and
              approach them with a critical mindset has left an indelible mark.
              Her deep thinking and analytical prowess consistently propelled
              our projects forward, offering innovative solutions and shedding
              light on intricate challenges. It's not just her capability to
              tackle complexities, but her adeptness at simplifying the most
              convoluted problems that truly sets her apart.”
            </p>
            <label for="expanded-adobe" role="button" className="expand-button">
              See
            </label>
          </div>
          <div className="person">
            <img
              src="/images/Araceli.jpg"
              alt="Headshot of Araceli Cubas Campodonico"
            />
            <div className="person-content">
              <p className="person-name">Araceli Cubas Campodonico</p>
              <p className="person-role">Senior UX Researcher @ Adobe</p>
            </div>
          </div>
        </div>
        <div className="testimonial-card chime">
          <img src="/images/chime.png" className="logo" alt="Chime Logo" />

          <div className="quote-content">
            <input type="checkbox" id="expanded-chime" />

            <p className="quote">
              “My initial impression of Allison was how visibly user-centric she
              was. I could easily sense the passion she had for understanding
              our users’ needs and I witnessed firsthand how frequently she
              successfully socialized her insights to influence product
              decision-making. As I worked more closely with her, I consistently
              found myself impressed by her unwavering commitment to delivering
              actionable outcomes with high-quality execution rooted in evidence
              and logic.”
            </p>
            <label for="expanded-chime" role="button" className="expand-button">
              See
            </label>
          </div>
          <div className="person">
            <img src="/images/Mark.jpg" alt="Headshot of Mark Sha" />

            <div className="person-content">
              <p className="person-name">Mark Sha</p>
              <p className="person-role">Senior Product Analyst @ Chime</p>
            </div>
          </div>
        </div>

        <div className="testimonial-card wonolo">
          <img src="/images/wonolo.png" className="logo" alt="Wonolo Logo" />
          <div className="quote-content">
            <input type="checkbox" id="expanded-wonolo" />

            <p className="quote">
              “Allison has been a rockstar in her role and our design/research
              team would not be where we are today without her. I could say many
              things, but I’ll speak to the one that I believe benefits the most
              and I don’t see often in many people - the ability and integrity
              to create a holistic viewpoint that informs her thoughts and work.
              It always impresses (and inspires) me how much effort and
              intention she gives to not only incorporate user research, but how
              she collaborates with multiple stakeholders in all areas of the
              business to create that wider understanding.”
            </p>
            <label
              for="expanded-wonolo"
              role="button"
              className="expand-button"
            >
              See
            </label>
          </div>
          <div className="person">
            <img src="/images/Briana.jpg" alt="Headshot of Briana Franko" />

            <div className="person-content">
              <p className="person-name">Briana Franko</p>
              <p className="person-role">
                Senior Manager, Product Design @ Wonolo
              </p>
            </div>
          </div>
        </div>

        <div className="testimonial-card benevity">
          <img
            src="/images/benevity.png"
            className="logo"
            alt="Benevity Logo"
          />
          <div className="quote-content">
            <input type="checkbox" id="expanded-benevity" />
            <p className="quote">
              “After interviewing a number of specialists from around the world
              we chose Allison to lead the UX portion of a major product
              interface overhaul. Allison brought a steady hand to our project
              team, thoughtfully guiding us towards the world we live in today,
              one where our solution is beautiful and useful and intuitive.
              Allison balances creativity and energy with pragmatism and
              patience. She can command a room and pull insights from senior
              leaders, or run a user test with internet-savvy teens. She is
              articulate, organized, and action-oriented. “
            </p>
            <label
              for="expanded-benevity"
              role="button"
              className="expand-button"
            >
              See
            </label>
          </div>
          <div className="person">
            <img src="/images/Chad.jpg" alt="Headshot of Chad Neufeld" />
            <div className="person-content">
              <p className="person-name">Chad Neufeld</p>
              <p className="person-role">
                Senior Product Marketing Manager @ Benevity
              </p>
            </div>
          </div>
        </div>

        <div className="testimonial-card good-seed">
          <img
            src="/images/good-seed.png"
            className="logo"
            alt="Good Seed Logo"
          />

          <div className="quote-content">
            <input type="checkbox" id="expanded-good-seed" />

            <p className="quote">
              “This sounds like hyperbole, I know, but Allison is truly one of
              the smartest and most talented people I’ve had the pleasure of
              working with over my career. Her ability to plan, deliver, and
              synthesize research outputs into a meaningful narrative that's
              chock-full of strategic insights, is second-to-none. She is adept
              at communicating complex topics simply and clearly. Her seemingly
              insatiable desire to understand human behaviour allows her to
              connect with people authentically and skillfully. She is guided by
              a strong sense of integrity and the dedication to improving
              everything she encounters.”
            </p>
            <label
              for="expanded-good-seed"
              role="button"
              className="expand-button"
            >
              See
            </label>
          </div>
          <div className="person">
            <img src="/images/Erica.jpg" alt="Headshot of Erica O’Donnell" />
            <div className="person-content">
              <p className="person-name">Erica O’Donnell</p>
              <p className="person-role">
                Strategist & Facilitator @ Good Seed
              </p>
            </div>
          </div>
        </div>
        <div className="testimonial-card daughter">
          <img
            src="/images/daughter.png"
            className="logo"
            alt="Daughter Logo"
          />

          <div className="quote-content">
            <input type="checkbox" id="expanded-daughter" />

            <p className="quote">
              “The passion Allison has for good, quality work is evident in her
              willingness and ability to explore the business strategy of the
              clients she works on, all the while drilling down to the real
              problem we’re trying to solve through research. She is a strong
              presenter who is articulate and thorough in her deliverables. She
              goes above and beyond with her work and is a great advocate for
              her team, the company, as well as the research discipline as a
              whole.”
            </p>
            <label
              for="expanded-daughter"
              role="button"
              className="expand-button"
            >
              See
            </label>
          </div>
          <div className="person">
            <img src="/images/Wendy.jpg" alt="Headshot of Wendy Chiu" />
            <div className="person-content">
              <p className="person-name">Wendy Chiu</p>
              <p className="person-role">
                Director of Client Services @ Daughter Creative
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="s-about">
      <div className="s-container">
        <div className="grid">
          <div className="about-image"></div>
          <div className="about-content">
            <h2>Allison Robins</h2>
            <h3>Mixed Methods Researcher</h3>
            <p>
              My first word was "why?" - Just kidding, it was probably "mama,"
              but I have been chasing answers since I could crawl.{' '}
            </p>
            <p>
              Now, I help my teams ask better questions, challenge their
              assumptions, and connect their visions with the details required
              to get them there. When I’m off the clock, I’m likely nerding out
              over politics, watching bad reality TV, or getting my sweat on.{' '}
            </p>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default IndexPage
